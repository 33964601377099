import React, { useState } from 'react';

const Modal = ({ showModal, setShowModal, message, imageUrl }) => {
  if (!showModal) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl w-full">
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Update Details</h2>
          <button onClick={() => setShowModal(false)} className="text-xl">&times;</button>
        </div>

        {/* Modal Body: Image Left, Content Right */}
        <div className="flex gap-6">
          {/* Image Section (Left) */}
          {imageUrl && (
            <div className="w-1/3">
              <img src={imageUrl} alt="Update" className="w-full h-auto rounded-lg" />
            </div>
          )}

          {/* Content Section (Right) */}
          <div className="w-2/3">
            <p>{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};


// News Item Component
const NewsItem = ({ heading, message, date, imageUrl }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="bg-[#daefef] p-4 rounded-md shadow-md mb-4">
      <h3 className="font-bold text-lg">{heading}</h3>
      <p className="text-gray-700">{message}</p>
      <div className="flex justify-between items-center mt-2">
        <span className="text-gray-500">{date}</span>
        <button
          onClick={() => setShowModal(true)}
          className="text-teal-500 font-medium hover:underline"
        >
          more info
        </button>
      </div>
      <Modal 
        showModal={showModal} 
        setShowModal={setShowModal} 
        message={message} 
        imageUrl={imageUrl}
      />
    </div>
  );
};

// News & Updates Component
const NewsUpdates = ({ data }) => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold mb-6">News & Updates</h2>
      {data.map((item, index) => (
        <NewsItem 
          key={index} 
          heading={item.heading} 
          message={item.message} 
          date={item.date} 
          imageUrl={item.imageUrl}
        />
      ))}
    </div>
  );
};

// Example Usage
const News = () => {
  const newsData = [
    {
      heading: 'Global Plans Now Available!',
      message: 'Users can now stay connected in 80+ countries with a single eSIM! Our new Global Plans provide seamless connectivity without the hassle of switching SIMs. Enjoy uninterrupted service wherever you travel.',
      date: '25/01/2025',
      imageUrl: 'https://4kwallpapers.com/images/walls/thumbs_3t/8934.jpg',
    },
    {
      heading: 'Simplified OTP Entry – Faster & Easier!',
      message: 'We’ve enhanced the OTP verification process, making it quicker and more user-friendly Copy the OTP<br> Paste it into the field Enter for instant verificationNo more manual typing—just three simple steps for a seamless login experience!',
      date: '24/01/2025',
      imageUrl: 'https://4kwallpapers.com/images/walls/thumbs_3t/8934.jpg',
    },
    {
      heading: 'Create Ticket" Section Updates',
      message: 'To improve issue resolution, we’ve updated the "Create Ticket" section in both the Partner and Super Admin View: pushpin: Label Change: "Sub Complaint Type" is now "Issue Type:pushpin: New Dropdown Options for Issue Type',

// Service Issue
// API Issue
// Payment and Finance',
      date: '23/01/2025',
      imageUrl: 'https://4kwallpapers.com/images/walls/thumbs_3t/8934.jpg',
    },
    // Add more updates as needed
  ];

  return (
    <div className="container mx-auto">
      <NewsUpdates data={newsData} />
    </div>
  );
};

export default News;
