import React, { useEffect, useState } from "react";
import tower from '../assets/images/tower.png';
import africaImage from '../assets/images/africa.svg';
import asiaImage from '../assets/images/asia.svg';
import caribbean from '../assets/images/caribbean.svg';
import europe from '../assets/images/europe.svg';
import MiddleEast from '../assets/images/MiddleEast.svg';
import NorthAmerica from '../assets/images/NorthAmerica.svg';
import Southlationamerica from '../assets/images/SouthLationAmerica.svg';
import Global from '../assets/images/Global.svg';

import { LuDatabase } from "react-icons/lu";
import { MdOutlineWatchLater, MdOutlineSpeed } from "react-icons/md";
import { HiOutlineGlobe } from "react-icons/hi";
import { IoPlayCircleOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { ImCross } from "react-icons/im";
import { GoDownload } from "react-icons/go";
import { saveAs } from 'file-saver';
import LoadingSpinner from "../components/LoadingSpinner";

const Plan = () => {
  const [planDetails, setPlanDetails] = useState(null);
  const [showQRModal, setShowQRModal] = useState(false);
  const [lpacode, setLpaCode] = useState('');
  const [purchaseDetails, setPurchaseDetails] = useState(null); // State to store purchase details for QR modal
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false); // To manage modal visibility
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable
  const [userRole, setUserRole] = useState(null); // State for user role

  useEffect(() => {
    // Fetch user role from localStorage
    const role = localStorage.getItem("role");
    setUserRole(role);
  }, []);
  useEffect(() => {
    const token = localStorage.getItem('token');
    const partnerCode = localStorage.getItem('partnerCode');
    const plan_id = localStorage.getItem('plan_id');

    fetch(`${apiUrl}Esim/Get_plan_details_byid?plan_id=${plan_id}&Partnercode=${partnerCode}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.isSuccess) {
        setPlanDetails(data);
      } else {
        console.error('Failed to fetch plan details:', data.message);
      }
    })
    .catch(error => {
      console.error('Error fetching plan details:', error);
    });
  }, []);

  
  
  const handleAddBundle = () => {
    const partnerCode = localStorage.getItem('partnerCode');
    const planCode = localStorage.getItem('planCode');
    const token = localStorage.getItem('token');
  
    if (!token) {
      console.error('No token found in localStorage');
      return;
    }
  
    setLoading(true); // Start loading spinner
  
    const requestData = {
      partnerCode: partnerCode,
      planCode: planCode,
      travelDate: "",
      quantity_plan: 1
    };
  
    fetch(`${apiUrl}Esim/PurhaseSim_Bundle`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      console.log('API response:', data);
      if (!data.isSuccess) {
        throw new Error(data.message);
      }
  
      // Safely check if purchaseplanlist is available and has elements
      const purchasePlan = data.purchaseplanlist && data.purchaseplanlist.length > 0 
        ? data.purchaseplanlist[0] 
        : null;
  
      // Only set the purchase details if the purchasePlan is available
      if (purchasePlan) {
        setPurchaseDetails(purchasePlan);
      }
  
      // Set the LPA code and show QR modal regardless of purchasePlan availability
      setLpaCode(data.lpacode);
      setShowQRModal(true);
  
      // Show success message in the modal
      setModalMessage('Plan purchased successfully!');
      setShowSuccessModal(true); // Show success modal
  
      // Optionally, show a success alert
      // alert('Plan purchased successfully!');
    // Automatically close success modal after 1 second
    setTimeout(() => {
      setShowSuccessModal(false);
    }, 1000);
    setTimeout(() => {
      setShowConfirmModal(false); // Close the confirmation modal after 4 seconds
    }, 1000);
  })
   
    .catch(error => {
      console.error('There was a problem with the API request:', error);
      alert('Error: ' + error.message);
    })
    .finally(() => {
      setLoading(false); // End loading
    });
  };
  
  
  const handleCrossClick = () => {
    navigate("/BuyDataBundles");
  };

  const handleDownload = (lpacode) => {
    // Simulate downloading logic here
    // For demonstration purposes, download a dummy QR code image
    const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${lpacode}&size=200x200`;
    saveAs(qrCodeUrl, `QR_${lpacode}.png`);
  };


  
  if (!planDetails) {
    return <div> <LoadingSpinner /></div>;
  }
  const handleCross = () => {
    setShowQRModal(false); // Set showQRModal state to false to close the modal
  };

  const { sellingcost, sellingcost_airhub, discount, planlist } = planDetails;
  const {  validity, dataallowance, plantype } = planlist[0];

  // Determine which image to use based on selectedRegionId
  const selectedRegionId = localStorage.getItem('selectedRegionId');
  let sidebarImage = tower;

  if (selectedRegionId === '1') {
    sidebarImage = africaImage;
  } else if (selectedRegionId === '3') {
    sidebarImage = asiaImage;
  } else if (selectedRegionId === '12') {
    sidebarImage = caribbean;
  } else if (selectedRegionId === '4') {
    sidebarImage = europe;
  } else if (selectedRegionId === '8') {
    sidebarImage = Global;
  } else if (selectedRegionId === '5') {
    sidebarImage = MiddleEast;
  } else if (selectedRegionId === '6') {
    sidebarImage = NorthAmerica;
  } else if (selectedRegionId === '7') {
    sidebarImage = Southlationamerica;
  }
  function SuccessModal({message, onClose }) {
    // if (!isOpen) return null;
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white rounded-lg p-6 shadow-lg max-w-sm w-full relative">
          <button
            onClick={onClose}
            className="absolute top-4 right-4 px-4 py-2  text-white rounded-lg hover:bg-[#20bfb2]"
          >
            <ImCross size={20} color="grey" />
          </button>
          <div className="flex flex-col items-center justify-center h-full">
            {/* <img src={tick} alt="ticksignup" className="mb-4" /> */}
            <p className="text-5xl font-bold">{message}</p>
          </div>
        </div>
      </div>
    );
  }
  return (
    
    <div style={{backgroundColor:'#e9f8f9'}} className="main-content flex h-screen overflow-hidden">
      <div className="sidebar w-/4 rounded-l-lg h-screen">
        <img src={sidebarImage} alt="Region Image" />
      </div>
      {showSuccessModal && (
  <SuccessModal
    message={modalMessage}
    onClose={() => setShowSuccessModal(false)} // Close the modal
  />
)}

      <div className="content flex-1 p-4 rounded-r-lg overflow-auto">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-5xl font-semibold">Details</h2>
          {/* <ImCross size={25} onClick={handleCrossClick} className="cursor-pointer" /> */}
        </div>
        <div className="mt-2">
          {planlist[0].addinformation.split('</ul>').map((item, index) => (
            <div key={index} className="flex m-2">
              <div dangerouslySetInnerHTML={{ __html: item }} />
            </div>
          ))}
        </div>
       
        <div style={{backgroundColor:'#daefef' }} className="rounded-2xl flex justify-between p-4">
          <div className="flex flex-col items-center">
            <LuDatabase size={30} />
            <span>{dataallowance}</span>
          </div>
          <div className="flex flex-col items-center">
            <MdOutlineWatchLater size={30} />
            <span>{validity} </span>
          </div>
          <div className="flex flex-col items-center">
            <HiOutlineGlobe size={30} />
            <span>{plantype}</span>
          </div>
          <div className="flex flex-col items-center">
            <MdOutlineSpeed size={30} />
            <span>2G/3G/4G/5G Data</span>
          </div>
          <div className="flex flex-col items-center">
            <IoPlayCircleOutline size={30} />
            <span>Autostart Enabled</span>
          </div>
        </div>
       
        {showQRModal && purchaseDetails && (
         <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center">
         <div className="bg-white p-8 rounded-md flex flex-row relative">
           {/* Left column */}
           <div className="flex flex-col items-start w-96">
             <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
               <span className="font-bold">Order ID:</span>{' '}
               <span className="ml-4">{purchaseDetails.orderid || 'N/A'}</span>
             </div>
             <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
               <span span className="font-bold">APN:</span>{' '}
               <span className="ml-4">{purchaseDetails.apn || 'N/A'}</span>

             </div>
             <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
               <span className="font-bold">ICCID:</span>{' '}
               <span className="ml-4">{purchaseDetails.iccid || 'N/A'}</span>
             </div>
             <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
               <span className="font-bold">SIMPIN:</span>{' '}
               <span className="ml-4">{purchaseDetails.simpin || 'N/A'}</span>
             </div>
             <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
               <span className="font-bold">SM-DP+Address:</span>{' '}
               <span className="ml-4">{purchaseDetails.address || 'N/A'}</span>
             </div>
             
             <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
               <span className="font-bold">Authentication code:</span>{' '}
               <span className="ml-4">{purchaseDetails.lpacode || 'N/A'}</span>
             </div>
             <div className="mb-4 p-4 w-full border-4 bg-[#c5f1f1] rounded-2xl">
               <span className="font-bold">How to Install ?</span>
               <a href="https://www.airhubapp.com/info/Installation" target="_blank" rel="noopener noreferrer" className="ml-4 border-2 rounded-2xl hover:bg-blue-400 p-2 border-black ">Step by Step Guide</a>
             </div>
           </div>

           {/* Right column */}
           <div className="flex flex-col items-center justify-center ml-8">
            <QRCode value={lpacode} className='border-4 p-2 border-[#47a192]' size={200} />
            <button
              className="text-[#595959] flex items-center border border-black px-4 py-2 rounded mt-4"
              onClick={() => handleDownload(lpacode)} 
              style={{ textDecoration: 'none' }}
            >
              <GoDownload size={30} /> Download
            </button>
          </div>


           {/* Close button */}
           <ImCross size={30} onClick={handleCross} className="cursor-pointer absolute top-2 right-2" />
           </div>
       </div>
        )}

         {/* RRA Price and Discount inputs with Buy Now button */}
      <div className="flex mt-32 items-center space-x-2">



      {userRole === "Partner" &&(

<div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                             <label className="text-teal-900 block font-bold mb-1"> Website  Price </label>
             <p className=" py-6 px-6 rounded-lg w-36 text-center font-bold    text-[2rem]  " >
             {`$${parseFloat(sellingcost_airhub).toFixed(2)}`}
             </p>
        </div> 
        <div className="w-full md:w-1/2 px-3">
        <label className="text-teal-900 block mb-1 text-center   font-bold">Discount 
        </label> <p         className=" py-6 px-6 rounded-lg w-36 text-center font-bold    text-[2rem]  " > {`${discount}% `} </p>
        </div>
        </div>
      )}

<div className="mb-2 mr-10">
<button
  className="rounded-full m-2 text-white w-full px-4 py-4 shadow-md hover:bg-white transition duration-200 ease-in"
  style={{ background: 'linear-gradient(to bottom right, #e8cd4d, #eebc3a, #f1b230, #f8a01c)' }}
  onClick={() => setShowConfirmModal(true)} // Show the modal on click
  disabled={loading}
>
  {userRole === "Partner" ? `$${parseFloat(sellingcost).toFixed(2)} ${loading ? 'Buying....' : 'Buy Now'}` : 'Buy'}
</button>

</div>


      </div>

      {showConfirmModal && (
  <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center">
    <div className="bg-white p-8 rounded-md flex flex-col items-center relative w-96">
      <h3 className="text-xl font-bold mb-4">Are you sure you want to purchase this plan?</h3>
      <div className="flex justify-around w-full">
      <button
                className="px-6 py-2 rounded-lg bg-yellow-500 text-white"
                onClick={() => {
                  // When Confirm is clicked, start loading and trigger timeout for modal close
                  handleAddBundle();
                }}
              >
                Confirm
              </button>
        <button
          className="px-6 py-2 rounded-lg bg-gray-500 text-white"
          onClick={() => setShowConfirmModal(false)} // Close the modal on cancel
        >
          Cancel
        </button>
      </div>
      <ImCross size={20} onClick={() => setShowConfirmModal(false)} className="absolute top-2 right-2 cursor-pointer" />
    </div>
  </div>
)}

 {/* Show Loading Spinner */}
 {loading && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <div className="spinner">
            <div className="loader"><LoadingSpinner/></div>
          </div>
        </div>
      )}
      </div>
    </div>
  );
};

export default Plan;