import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import HamburgerButton from "./HamburgerMenuButton/HamburgerButton";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const location = useLocation();
  const [walletData, setWalletData] = useState(null);
  const [sessionToken, setSessionToken] = useState(null);
  const [partnerCode, setPartnerCode] = useState(null);
  const [userName, setUserName] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false); // State to track admin role
  const [isPartner, setIsPartner] = useState(false); // State to track partner role
  const [isPayaspergo, setIsPayaspergo] = useState(false); // State to track partner role
  const [isfinance, setIsfinance] = useState(false); // State to track partner role

  const [userRole, setUserRole] = useState(null); // State for user role
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable
  const navigate = useNavigate();
 
  useEffect(() => {
    const fetchSessionData = () => {
      const storedSessionToken = localStorage.getItem("token");
      const storedPartnerCode = localStorage.getItem("partnerCode");
      const storedUserName = localStorage.getItem("Name");
      const storedRole = localStorage.getItem("role"); // Fetch user role

      if (
        storedSessionToken &&
        storedPartnerCode &&
        storedUserName &&
        storedRole
      ) {
        setSessionToken(storedSessionToken);
        setPartnerCode(storedPartnerCode);
        setUserName(storedUserName);
        setIsAdmin(storedRole === "admin"); // Check if user is admin
        setIsPartner(storedRole === "Partner"); // Check if user is partner
        setIsPayaspergo(storedRole === "Payaspergo"); // Check if user is partner
        setIsfinance(storedRole === "finance"); // Check if user is partner
        } else {
        navigate("/");
      }
    };
    fetchSessionData();
  }, [navigate]);

  useEffect(() => {
    const fetchWalletData = async () => {
      const sessionToken = localStorage.getItem("token");
      const partnerCode = localStorage.getItem("partnerCode");

      try {
        if (!sessionToken || !partnerCode) {
          console.error(
            "Session token or partner code missing",
            sessionToken,
            partnerCode
          );
          return;
        }

        const response = await axios.get(
          `${apiUrl}ESIMGOPartner/get_wallet_invidual?partnercode=${partnerCode}`,
          {
            headers: {
              Authorization: `Bearer ${sessionToken}`,
            },
          }
        );
        setWalletData(response.data.getindividualwallet[0]);
      } catch (error) {
        console.error("Error fetching wallet data:", error);
      }
    };

    fetchWalletData();
  }, [sessionToken, partnerCode]);

  const firstMenuItems = [
    // { title: 'Dashboard', path: '/Home', icon: '/icons.svg' },
    // { title: 'eSIMs Store', path: '/BuyDataFlag', icon: '/icons1.svg' },
    // { title: 'Transaction History', path: '/GetplanInformation', icon: '/icons6.svg' },
    // { title: 'Wallet Top-Up', path: '/TopUps', icon: '/icons3.svg' },
    // { title: 'News & Updates', path: '/News', icon: '/icons4.svg' },
  ];

  if (isAdmin) {
    firstMenuItems.push(
      { title: "Dashboard", path: "/SuperadminDashboard", icon: "/icons.svg" },
      { title: "Manage Partner", path: "/Superadmin", icon: "/icons6.svg" }
    );
  }
  if (isfinance) {
    firstMenuItems.push(
      { title: "Dashboard", path: "/SuperadminDashboard", icon: "/icons.svg" },
      { title: "Manage Partner", path: "/Superadmin", icon: "/icons6.svg" },
      // {title: "Create Parnter",path: "/SuperadminPartner",icon: "/icons2.svg",},
      // {title: "SuperadminUpdate List ",path: "/SuperadminVendor",icon: "/icons5.svg",},
      // { title: "Partner Update ", path: "/Superadminpay", icon: "/icons1.svg" },
      // { title: "API Box", path: "/ApiBox", icon: "/icons3.svg" },
      // { title: "Tickets", path: "/SuperadminTicket", icon: "/icons7.svg" },
      // { title: "Vendor Lists", path: "/SuperadminUpdate", icon: "/icons4.svg" },
    );
  }

  if (isPartner) {
    firstMenuItems.push(
      { title: "Dashboard", path: "/Home", icon: "/icons.svg" },
      { title: "eSIMs Store", path: "/BuyDataFlag", icon: "/icons1.svg" },
      // { title: "Regional eSIMs Store", path: "/Buydatad", icon: "/icons2.svg" },
      {title: "Transactions",path: "/GetplanInformation",icon: "/icons6.svg"},
      { title: "Wallet Top-Up", path: "/AddmoreBalance", icon: "/icons3.svg" },
      { title: "News & Updates", path: "/News", icon: "/icons4.svg" }
    );
  }
  if (isPayaspergo) {
    firstMenuItems.push(
      { title: "Dashboard", path: "/Home", icon: "/icons.svg" },
      { title: "eSIMs Store", path: "/BuyDataFlag", icon: "/icons1.svg" },
      // { title: "Regional eSIMs Store", path: "/Buydatad", icon: "/icons2.svg" },
      { title: "News & Updates", path: "/News", icon: "/icons4.svg" }
    );
  }
  
  const secondMenuItems = [
    // { title: 'Purchase History', path: '/SimManagement', icon: '/icons8.svg' },
    // Add more second menu items as needed
  ];
  if (isAdmin) {
    secondMenuItems.push(
      {title: "Purchase Details",path: "/SuperadminWallet",icon: "/icons8.svg",},
      {title: "Create Parnter",path: "/SuperadminPartner",icon: "/icons2.svg",},
      // {title: "SuperadminUpdate List ",path: "/SuperadminVendor",icon: "/icons5.svg",},
      // { title: "Partner Update ", path: "/Superadminpay", icon: "/icons1.svg" },
      { title: "API Box", path: "/ApiBox", icon: "/icons3.svg" },
      { title: "Tickets", path: "/SuperadminTicket", icon: "/icons7.svg" },
      // { title: "Vendor Lists", path: "/SuperadminUpdate", icon: "/icons4.svg" },

      
    );
  }
  if (isPartner) {
    secondMenuItems.push({title: "Purchase History",path: "/SimMangement",icon: "/icons8.svg",});
  }
  if (isPayaspergo) {
    secondMenuItems.push({title: "Purchase History",path: "/SimMangement",icon: "/icons8.svg",    });
  }
  if (isfinance) {
    secondMenuItems.push( 
       {title: "Purchase Details",path: "/SuperadminWallet",icon: "/icons8.svg",},
       { title: "Tickets", path: "/SuperadminTicket", icon: "/icons7.svg" },

      );
  

  }



  const thirdMenuItems = [
    // { title: 'API Box', path: '/ApiBox', icon: '/icons7.svg' },
    // { title: 'Help & Support', path: '/HelpSupport', icon: '/icons8.svg' },
    // { title: 'Create Ticket', path: '/CreateTicket', icon: '/icons2.svg' },
    // // Add more third menu items as needed
  ];

  if (isPartner) {
    thirdMenuItems.push(
      { title: "API Box", path: "/ApiBox", icon: "/icons7.svg" },
      { title: "Help & Support", path: "/HelpSupport", icon: "/iconhelp.svg" },
      { title: "Create Ticket", path: "/CreateTicket", icon: "/icons2.svg" }
      // Add more third menu items as needed
    );
  }
  if (isPayaspergo) {
    thirdMenuItems.push(
      { title: "API Box", path: "/ApiBox", icon: "/icons7.svg" },
      { title: "Help & Support", path: "/HelpSupport", icon: "/iconhelp.svg" },
      { title: "Create Ticket", path: "/CreateTicket", icon: "/icons2.svg" }
      // Add more third menu items as needed
    );
  }

  const renderMenuItem = (menu, index) => {
    const isSelected = location.pathname === menu.path;
    const iconStyle = isSelected
      ? { filter: "invert(100%) brightness(100%)" }
      : {};
      return (
        <Link to={menu.path} key={index}>
          <li
            className={`flex items-center gap-x-3 p-3 text-base relative font-large cursor-pointer  ${
              isSelected ?'bg-[#117575] text-white dark:bg-gray-700':"dark:text-white hover:bg-[#dbedec] dark:hover:bg-gray-700"
            }`}
          >
            <img className="w-5 h-5 relative overflow-hidden shrink-0" alt="" src={menu.icon} style={iconStyle} />
            <span className={`origin-left duration-300 hover:block`}>{menu.title}</span>
          </li>
        </Link>
      );
    };

    useEffect(() => {
      // Fetch user role from localStorage
      const role = localStorage.getItem("role");
      setUserRole(role);
    }, []);
  
    return (
      <>
        <div     className={` bg-[#ffffff] hidden lg:basis-1/4 xl:basis-1/5 lg:block h-screen sticky top-0 duration-300 p-2 rounded-xl  `}>
  
        <div style={{ background: 'linear-gradient(328deg, rgba(18,152,170,1) 0%,rgba(41,215,183,1) 100%)' }}  className="text-white p-4  rounded-3xl">
          
         
            <p className="text-white font-bold pb-2 p-6 " style={{fontSize:"22px"}}>{userName}</p>
            <p className="text-white p-2">
  {userRole === 'Partner' && (
    <>
      {walletData ? (
        <span style={{ backgroundColor: '#4cc8c1' }} className="text-4xl font-semibold p-2">
          {`$${walletData.balance}`}
        </span>
      ) : (
        <span className="text-lg text-gray-600">Loading...</span>
      )}
    </>
  )}
</p>



          </div>
  
          <ul className='pt-2 overflow-y-auto' style={{ maxHeight: 'calc(80vh - 172px)' }}>
            {firstMenuItems.map(renderMenuItem)}
          </ul>
  
          <hr className="my-4 border-gray-400" />
  
          <ul className='pt-2 overflow-y-auto' style={{ maxHeight: 'calc(100vh - 372px)' }}>
            {secondMenuItems.map(renderMenuItem)}
          </ul>
  
          <hr className="my-4 border-gray-400" />
  
          <ul className=' overflow-y-auto' style={{ maxHeight: 'calc(100vh - 272px)' }}>
            {thirdMenuItems.map(renderMenuItem)}
          </ul>
        </div>
  
        {/* Mobile Menu */}
        <div className="pt-3 bg-red-400">
          <HamburgerButton
            setMobileMenu={setMobileMenu}
            mobileMenu={mobileMenu}
          />
        </div>
  
        <div className="lg:hidden">
        <div className={`
  ${mobileMenu ? 'flex' : 'hidden'}
  absolute z-50 flex-col items-center self-start mt-16 space-y-6 font-bold 
  dark:text-white bg-gray-50 dark:bg-slate-800 drop-shadow md:rounded-xl
`}>
            
  {firstMenuItems.map((menu, index) => (
              <Link
                to={menu.path}
                key={index}
                onClick={() => setMobileMenu(false)}
              >
                <span
                  className={` ${
                    location.pathname === menu.path &&
                    'bg-[#07AEA4] dark:bg-gray-700'
                  } p-2 rounded-xl hover:bg-[#07AEA4]  dark:hover:bg-gray-700`}
                >
                  {menu.title}
                </span>
              </Link>
            ))}
            <hr className=" border-gray-400" />
            {secondMenuItems.map((menu, index) => (
              <Link
                to={menu.path}
                key={index}
                onClick={() => setMobileMenu(false)}
              >
                <span
                  className={` ${
                    location.pathname === menu.path &&
                    'bg-[#07AEA4] dark:bg-gray-700'
                  } p-2 rounded-xl hover:bg-[#07AEA4] dark:hover:bg-gray-700`}
                >
                  {menu.title}
                </span>
              </Link>
            ))}
            <hr className="my-4 border-gray-400" />
            {thirdMenuItems.map((menu, index) => (
              <Link
                to={menu.path}
                key={index}
                onClick={() => setMobileMenu(false)}
              >
                <span
                  className={` ${
                    location.pathname === menu.path &&
                    'bg-[#07AEA4] dark:bg-gray-700'
                  } p-2 rounded-xl hover:bg-[#07AEA4] dark:hover:bg-gray-700`}
                >
                  {menu.title}
                </span>
              </Link>
            ))}
          </div>
        </div>
      </>
    );
  };
  

export default Sidebar;