import React, { useState, useEffect } from "react";
import { IoMdSearch } from "react-icons/io";
import { ImCross } from "react-icons/im"; // Import the cross icon
import Modal from "react-modal"; // Import react-modal for handling modals
import Plan from "./Plan"; // Import the Plan component
import Local from '../assets/images/local.svg';
import GlobalPlan from '../assets/images/Globalplan.svg';
import Regional from '../assets/images/Regional.svg'
import { Navigate, useNavigate } from "react-router-dom";
import { GoDownload } from "react-icons/go";

const BuyDataFlag = () => {
  const navigate= useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable
  const [countries, setCountries] = useState([]); // All countries for search
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [plans, setPlans] = useState([]); // State to store the plans
  const [selectedPlan, setSelectedPlan] = useState(null); // State for selected plan details
  const [modalIsOpen, setModalIsOpen] = useState(false); // State for modal visibility
  const [selected,setSelected]=useState();
  const [error, setError] = useState(null);
  const [topCountries, setTopCountries] = useState([
    { id: 12, countryname: "USA" },
    { id: 28, countryname: "UK" },
    { id: 170, countryname: "Thailand" },
    { id: 4, countryname: "Japan" },
    { id: 5, countryname: "South Korea" },
    { id: 8, countryname: "UAE" },
  ]);

  // Fetch all countries (for searching)
  useEffect(() => {
    const token = localStorage.getItem("token");

    fetch("https://esimgo.clay.in/api/ESIM/Get_all_country?flag=1", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.isSuccess && Array.isArray(data.getallcountry)) {
          setCountries(data.getallcountry); // Store all countries for searching
        }
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  // **Set Default Selected Country (USA) on Load**
  useEffect(() => {
    const defaultCountry = topCountries.find((country) => country.countryname === "USA");
    if (defaultCountry) {
      setSelectedCountry(defaultCountry);
      localStorage.setItem("region", defaultCountry.id);
    }
  }, [topCountries]);

  // Fetch plans when a country is selected
  useEffect(() => {
    if (selectedCountry) {
      const token = localStorage.getItem("token");
      const region = localStorage.getItem("region");
      const partnerCode = localStorage.getItem("partnerCode");
  
      const requestBody = {
        region_id: "someRegionId",
        country: region,
        partnerCode: partnerCode,
      };
  
      fetch("https://esimgo.clay.in/api/ESIM/Getplan_bycountry", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.isSuccess) {  // Change 'response' to 'data'
            const plans = data.getofflineplan;  // Change 'response' to 'data'
  
            // Function to convert the plan data for sorting (similar to previous approach)
            const parseData = (data) => {
              if (!data) return Infinity; // Treat null as the largest value
              const unit = data.slice(-2); // Extract the last two characters (MB/GB)
              const value = parseInt(data.slice(0, -2), 10); // Extract the numeric part
              return unit === "GB" ? value * 1000 : value; // Convert GB to a larger unit (e.g., 1GB = 1000MB)
            };
  
            // Sort plans by the parsed data
            const sortedPlans = plans.sort((a, b) => parseData(a.data) - parseData(b.data));
  
            setPlans(sortedPlans);  // Use sorted plans
          }
        })
        .catch((error) => console.error("Error fetching plans:", error));
    }
  }, [selectedCountry]);
  


  // Handle search input
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  // Handle country selection
  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    localStorage.setItem("region", country.id);
    setSearchQuery(""); // Clear the search query

  };

  // Filter countries based on search query
  const filteredCountries = countries.filter((country) =>
    country.countryname.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Display either topCountries (default) or search results
  const displayCountries = searchQuery ? filteredCountries : topCountries;
  const handleBuyClick = (plan) => {
    localStorage.setItem("planCode", plan.plancode); // Store planid in session storage
    localStorage.setItem("plan_id", plan.planid); // Store planid in session storage

    setPlans(plan);
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handleNavigation = (storeType) => {
    setSelected(storeType);

    if (storeType === "local") {
      navigate("/BuyDataFlag"); // Navigate to Home
    } else if (storeType === "regional") {
      navigate("/BuyDataRegional"); // Navigate to Regional eSIM
    }else{
      navigate("/BuyDataGlobal")
    }
  };
  const downloadCSV = async () => {
    try {
      const token = localStorage.getItem("token");
      const partnerCode = localStorage.getItem("partnerCode");

      if (!token) {
        setError("No token found. Please log in.");
        return;
      }
      const response = await fetch(`${apiUrl}ESIM/Get_all_plans?flag=1&PartnerCode=${partnerCode}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });
  
      const data = await response.json();
  
      if (data.isSuccess) {
        let { getallplan } = data;
      
        // Sort the plans alphabetically by country
        getallplan = getallplan.sort((a, b) => a.country.localeCompare(b.country));
      
        const header = 'Country,Data,Validity,Website Price,Portal Price,plan_Class\n';
      
        // Retrieve discount from localStorage (default to 20% if not set)
        const discount = parseFloat(localStorage.getItem('Discount')); // Default to 20 if not set
      console.log(discount)
        const rows = getallplan.map(plan => {
          // Calculate the discounted website price based on the discount from localStorage
          const portalPrice = parseFloat(plan.sellingcost);
          const websitePrice = portalPrice * (1 - (discount / 100)); // Apply the discount (100% - discount)
      
          return `${plan.country},${plan.data === "" ? "Unlimited" : plan.data},${plan.validity},$${portalPrice.toFixed(2)},$${websitePrice.toFixed(2)},${plan.plan_Class},`;
        }).join('\n');
        
        // Combine header and rows
      
            
  
        const csvContent = header + rows;
  
        // Create a blob and trigger download
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'plans.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };
  
  return (
    <>
    <div>
    <div className="flex shadow-2xl m-2">
      <button
        onClick={() => handleNavigation("local")}
        className={`self-stretch shadow-[0px_2px_8px_rgba(92,_152,_140,_0.3)] rounded-lg h-10 flex flex-row items-center justify-center p-2.5 box-border m-4 ${
          selected === "local"
            ? "bg-brand-white text-brand-green-selected"
            : "bg-brand-green-selected text-brand-white"
        }`}
      >
        <img src={Regional}
          className={`mr-2 ${
            selected === "local" ? "text-brand-green-selected" : "text-brand-white"
          }`}
        />
        Local eSIM store
      </button>


      <button
        onClick={() => handleNavigation("regional")}
        className={`self-stretch shadow-[0px_2px_8px_rgba(92,_152,_140,_0.3)] rounded-lg h-10 flex flex-row items-center justify-center p-2.5 box-border m-4 `}
      >
        <img src={Local}
         className="h-6 w-6 filter invert sepia saturate-500 hue-rotate-180 brightness-150 contrast-100"
         alt="Regional Icon"
      
        />
        Regional eSIM store
      </button>
    </div>

      <div className="bg-[#117575] p-3 mb-2 rounded-xl">
        <div className="relative">
           <input
    type="text"
    placeholder="Search Countries"
    className="w-full self-stretch  rounded-2xl bg-brand-gray h-10 flex flex-row items-center justify-start box-border gap-[12px] mr-2 pl-10"
   value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
  />
          <div className="absolute inset-y-0 left-0 flex items-center pl-3">
            <IoMdSearch size={20} className="text-gray-500" />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-3 justify-between bg-gray-100 p-3 relative">
  {/* Search Input */}
 
  {selectedCountry && (
    <div className="flex">
      <span className="bg-[#c5e1e2] text-black font-semibold rounded-md shadow-md p-2 flex items-center">
        <ImCross
          size={12}
          className="cursor-pointer mr-2"
          onClick={() => setSelectedCountry(null)}
        />
        {selectedCountry.countryname}
      </span>
    </div>
  )}

  

  {/* Conditionally Render Dropdown When Searching */}
 {/* Conditionally Render Dropdown When Searching */}
{searchQuery && filteredCountries.length > 0 && (
  <div className="absolute top-[100%] left-0 w-full bg-white border border-gray-300 rounded-md shadow-md z-10 mt-1 h-44 overflow-y-auto">
    {filteredCountries.map((country) => (
      <div
        key={country.id}
        className="p-2 cursor-pointer hover:bg-gray-100 flex items-center gap-4 border-b border-gray-200"
        onClick={() => handleCountrySelect(country)}
      >
        <span className="text-gray-800 text-lg font-semibold flex-1">{country.countryname}</span>
        <img
          src={`https://www.airhubapp.com/assets/flags/${country.countryname}.svg`}
          alt={`${country.countryname} flag`}
          className="w-12 h-12 rounded-full"
        />
      </div>
    ))}
  </div>
)}

  {/* Top Countries Grid (Hidden When Searching) */}
  {!searchQuery && (
    <>
      <h1 className="text-2xl font-semibold text-gray-800">Top Countries</h1>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-6">
        {displayCountries.map((country) => (
          <div
            key={country.id}
            className={`border-2 border-gray-300 rounded-lg p-1 cursor-pointer transition-transform transform hover:scale-105 hover:shadow-lg ${
              selectedCountry?.id === country.id ? "bg-[#DAEFEF] border-[#117575]" : ""
            }`}
            onClick={() => handleCountrySelect(country)}
          >
            <div className="w-20 h-24 flex flex-col items-center justify-center">
              <img
                src={`https://www.airhubapp.com/assets/flags/${country.countryname}.svg`}
                alt={`${country.countryname} flag`}
                className="w-12 h-12 mb-2"
              />
              <h2 className="text-sm font-semibold text-gray-700">{country.countryname}</h2>
            </div>
          </div>
        ))}
      </div>
    </>
  )}
</div>

      







      {/* Display plans in table format */}
      {plans.length > 0 && (
        <div className="mt-4 p-4 border rounded-md bg-gray-100">
       <div className="flex items-center justify-between mb-4">
  {selectedCountry && (
    <div className="flex items-center">
      <img
        src={`https://www.airhubapp.com/assets/flags/${selectedCountry.countryname}.svg`}
        alt={`${selectedCountry.countryname} flag`} 
        className="w-12 h-12 mb-2"
      />
      <span className="ml-3 text-5xl text-black font-bold">{selectedCountry.countryname} Packages</span>
    </div>
  )}
  <button
    onClick={downloadCSV}
    className="px-4 py-2 text-[#105255] rounded-md font-bold flex items-center space-x-2"
  >
    <GoDownload size={28} />
    <span>Export</span>
  </button> 
</div>


          <table className="w-full mt-4">
            <thead className="py-2 px-4 mb-4">
              <tr className="border-b">
                <th className="text-center p-3 px-5">Country/Region</th>
                <th className="text-center p-3 px-5">Data</th>
                <th className="text-center p-3 px-5">Validity</th>
                <th className="text-center p-3 px-5">Plan Code</th>
                <th className="text-center p-3 px-5">Price</th>
                <th className="text-center p-3 px-5">Operator</th>
                {/* <th className="text-center p-2 px-2">Buy</th> */}
              </tr>
            </thead>
            <tbody>
              {plans.map((plan) => (
                <tr key={plan.planid} className="border-b hover:bg-gray-100">
                  <td className="text-center p-3 px-5">{plan.geography}</td>
                  <td className="text-center p-3 px-5">
                 {plan.data !== "" ? plan.data : "Unlimited"}
                 </td>
                  <td className="text-center p-3 px-5">{plan.vaildity}</td>
                  <td className="text-center p-3 px-5">{plan.plancode}</td>
                  <td className="text-center p-3 px-5">${plan.sellingcost}</td>
                  <td className="text-center p-3 px-5">{plan.operatorname}</td>
                 
                  <td className="text-center ">
                     <button className="self-stretch shadow-[0px_2px_8px_rgba(92,_152,_140,_0.3)]  rounded-xl bg-brand-green-selected  border-blue-500 h-10 flex flex-row items-center justify-center p-4 box-border text-brand-white m-4"
                       onClick={() => handleBuyClick(plan)}>Buy</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
       <Modal
       isOpen={modalIsOpen}
       onRequestClose={closeModal}
       style={{
         overlay: {
           backgroundColor: "rgba(0, 0, 0, 0.5)",
         },
         content: {
           width: "80%",
           margin: "auto",
           padding: "0px",
           borderRadius: "8px",
           height: "fit-content",
         },
       }}
     >
       <div className="absolute top-4 right-5 flex justify-center items-center">
         <button onClick={closeModal} className="text-black">
           <ImCross size={20} />
         </button>
       </div>
       {setPlans && <Plan plan={setPlans} closeModal={closeModal} />}
     </Modal>
     </>
  );
};

export default BuyDataFlag;

