import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";

function CreateTicket() {
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('');
    const [iccid, setIccid] = useState('');
    const [description, setDescription] = useState('');
    const apiUrl = process.env.REACT_APP_BASE_URL;

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleIccidChange = (event) => {
        setIccid(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleNavigateToDetails = async () => {
        const partnerCode = localStorage.getItem('partnerCode');
        const token = localStorage.getItem('token');

        const payload = {
            iccid: iccid,
            descrition: description,
            partnercode: partnerCode,
            issuedepartment: selectedOption,
        };

        // Show alert with the payload
        // alert(`Sending the following data:\nICCID: ${iccid}\nDescription: ${description}\nPartner Code: ${partnerCode}\nIssue Department: ${selectedOption}`);

        try {
            const response = await fetch(`${apiUrl}ESIM/CreateTicket`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                alert('Ticket successfully created!');
                // Navigate to YourTicket page upon success
                navigate('/YourTicket');
            } else {
                // Handle errors
                console.error('Error creating ticket:', response.statusText);
                alert('Failed to create ticket. Please try again.');
            }
        } catch (error) {
            console.error('Error creating ticket:', error);
            alert('Failed to create ticket. Please try again.');
        }
    };
    const handleNavigateToTicket = () => {
        navigate('/YourTicket');
      };

    return (
        <>
            <div className="w-full flex justify-center items-center ">
                <div className="rounded-2xl p-8 mb-12 md:mb-0 md:w-8/12 lg:w-5/12 xl:w-5/12 xl:p-6 dark:bg-gray-800 dark:border-gray-700" style={{ backgroundColor: '#ffffff' }}>
                    <div className="flex items-center mb-8">
                        {/* <div className="arrow h-16 w-16 flex justify-center items-center rounded-full bg-transparent shadow-md border-2 border-green-200">
                            <Link to="/Home" className="text-2xl"><FaArrowLeft /></Link>
                        </div> */}
                        <h1 className="font-semibold text-3xl ml-4">Create a Ticket</h1>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 rounded-full" htmlFor="grid-iccid">
                                Order ID
                            </label>
                            <input
                                style={{ backgroundColor: '#ffffff', border: '1px solid black' }}
                                className="appearance-none block w-full bg-black-200 text-gray-700 border border-black-200 rounded-full py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-iccid"
                                type="text"
                                placeholder="Enter Order ID"
                                value={iccid}
                                onChange={handleIccidChange}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-description">
                                Description
                            </label>
                            <input
                                style={{ backgroundColor: '#ffffff', border: '1px solid black' }}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded-full py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-description"
                                type="text"
                                placeholder="Enter Description"
                                value={description}
                                onChange={handleDescriptionChange}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-issuedepartment">
                                Issue Type
                            </label>
                            <select
                                value={selectedOption}
                                onChange={handleOptionChange}
                                className="w-full text-center border-2 rounded-full m-2 text-black  px-4 py-2 shadow-md hover:bg-white transition duration-200 ease-in"
                                style={{ backgroundColor: '#ffffff' }}
                                id="grid-issuedepartment"
                            >
                                <option value="0">Select Issue</option>
                                <option value="1">Service Issue</option>
                                <option value="3">API/Techinical Issue</option>
                                <option value="2">Payment & Finance</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="file-upload">
                                 Supportive Document
                            </label>
                            <input
                                type="file"
                                id="file-upload"
                                className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-full py-3 px-4 mb-3 leading-tight focus:outline-none"
                                accept=".pdf" // Restricts file upload to PDF only

                            />
                        </div>
                    </div>
                    <div className="btn flex justify-center items-center">
                        <button
                            className="rounded-full m-2 text-white w-3/6 px-4 py-4 shadow-md hover:bg-white transition duration-200 ease-in"
                            style={{ background: 'linear-gradient(to bottom right, #27d0b6, #24c8b5, #1bb1af, #18a8ad)' }}
                            onClick={handleNavigateToDetails}
                        >
                            Create Ticket
                        </button>
                        <button className='rounded-full m-2 text-white w-3/6 px-4 py-4 shadow-md hover:bg-white transition duration-200 ease-in' style={{ background: 'linear-gradient(to bottom right, #27d0b6, #24c8b5, #1bb1af, #18a8ad)' }} onClick={handleNavigateToTicket}>
        View Tickets
    </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateTicket;
