import React, { useState, useEffect } from 'react';
import Loader from '../assets/images/Loader.svg'
const LoadingSpinner = () => {
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      // Set the loader to hide after 2 minutes (120000 milliseconds)
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000);
  
      // Cleanup timer if the component unmounts before the 2 minutes
      return () => clearTimeout(timer);
    }, []);
    
    return (
    <div className="flex justify-center items-center h-screen">
      <div >
        <img src={Loader}/>
      </div>
    </div>
  );
};

export default LoadingSpinner;
