import React, { useState,useEffect } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import iota from "../assets/images/iota.svg";
import lock from "../assets/images/lock.svg";

const Login = () => {
  const [currentPage, setCurrentPage] = useState("login");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill('')); // Assuming 6-digit OTP
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const handleSignupClick = () => {
    navigate("/Signup");
  };
  const handleForgotclick = () => {
    navigate("/Forgotpassword");
  };

  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // useEffect(() => {
  //   // Check if token exists in localStorage
  //   const token = localStorage.getItem("token");
  //   if (!token) {
  //     alert("Token is expired. Please login again.");
  //     navigate("/"); // Redirect to login page
  //   }
  // }, [navigate]); // Run this effect on component mount

  const handleLoginSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}Authentication/UserLogin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userName, password }),
      });

      // Check if the response is empty
      if (response.status === 204) {
        setMessage("No content received from the server.");
        return;
      }

      // Attempt to parse JSON, handling cases where parsing fails
      let data = {};
      try {
        data = await response.json();
      } catch (err) {
        console.error("Failed to parse JSON response", err);
        setMessage("Failed to parse response from server.");
        return;
      }

      console.log("API Response:", data); // Debugging line

      if (response.ok) {
        if (data.partnerCode) {
          localStorage.setItem("partnerCode", data.partnerCode);
          setCurrentPage("otp");
        } else {
          setMessage("Email or Password incorrect");
        }
      } else {
        setMessage("Login Failed.");
      }
    } catch (error) {
      console.error("error", error);
      setMessage("Email or password Incorrect");
    } finally {
      setLoading(false);
    }
  };
  const handleOtpChange = (event, index) => {
    const { value } = event.target;
    const newOtp = [...otp];
    const inputValue = value.charAt(0);
  
    newOtp[index] = inputValue;
    setOtp(newOtp);
  
    // Move focus to the next input field if a digit is entered
    if (inputValue && index < otp.length - 1) {
      const nextInput = event.target.nextElementSibling;
      if (nextInput) {
        nextInput.focus();
      }
    }
  };
  
  // Handle paste functionality
  const handlePaste = (event) => {
    const pastedValue = event.clipboardData.getData('text/plain');
    const newOtp = pastedValue.split('').slice(0, 6); // Ensure only 6 digits
    setOtp(newOtp);
    console.log('ne', newOtp);
  
    // Focus on the next empty input field after pasting
    let nextEmptyIndex = newOtp.findIndex((digit) => digit === '');
    if (nextEmptyIndex === -1) {
      nextEmptyIndex = newOtp.length;
    }
  
    const otpInputs = document.querySelectorAll('input[name="otp"]');
    otpInputs[nextEmptyIndex]?.focus();
  };
  


  const handleOtpSubmit = async () => {
    setLoading(true);
    const partnerCode = localStorage.getItem("partnerCode");
// Construct the payload
const payload = { otp, partnerCode };

// Show the payload in an alert
// alert(JSON.stringify(payload, null, 2)); // Using JSON.stringify for pretty-printing

    try {
      const response = await fetch(`${apiUrl}Authentication/Verify_loginuser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({otp: otp.join(''), partnerCode }),
      });

      // Check if the response is empty
      if (response.status === 204) {
        setMessage("No content received from the server.");
        return;
      }

      // Attempt to parse JSON, handling cases where parsing fails
      let data = {};
      try {
        data = await response.json();
      } catch (err) {
        console.error("Incorrect Email or Password", err);
        setMessage("Incorrect Email or Password.");
        return;
      }

      if (response.ok) {
        // Store additional data in localStorage

        localStorage.setItem("token", data.token);
        localStorage.setItem("userName", data.data.userID); // Use 'userID' instead of 'userName'
        localStorage.setItem("role", data.data.role);
        localStorage.setItem("partnerCode", data.data.partnerCode);
        localStorage.setItem("Discount", data.data.markupdiscount);
        localStorage.setItem("Name", data.data.userName);


        const logoutTime = Date.now() + 900000; // Set logout time 15 minutes from now
    
        // Calculate and log the remaining time before logout
        const remainingTime = Math.ceil((logoutTime - Date.now()) / 1000);
        console.log(`Time left to logout: ${remainingTime} seconds`);

        // Set a timeout to remove user credentials after 30 minutes
        setTimeout(() => {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            localStorage.removeItem('role');
            console.log("Session expired, user logged out");
            alert("Timeout");
            navigate("/");
        }, 18000000);

        // Navigate based on user role
        if (data.data.role === 'Partner') {
            navigate('/Step1');
        } else if (data.data.role === 'admin') {
            navigate('/SuperadminDashboard');
        }  else if (data.data.role === 'Payaspergo') {
          navigate('/Step1');
      } 
      else if (data.data.role === 'finance') {
        navigate('/SuperadminDashboard');
    } 
        else {
            // Handle other roles or navigate to a default page
            navigate('/DefaultDashboard');
        }
    }      
       else {
        setMessage("OTP Verification Failed.");
      }
    } catch (error) {
      console.error("error", error);
      setMessage("Incorrect OTP");
    } finally {
      setLoading(false);
    }
  };

  if (currentPage === "login") {
    return (
      <div className="login bg-daefef h-screen w-full flex justify-center items-center">
        <div
          className="rounded-2xl mb-12 md:mb-0 md:w-8/12 lg:w-5/12 xl:w-5/12 xl:p-6 dark:bg-gray-800 dark:border-gray-700 p-8 m-8"
          style={{ backgroundColor: "#ffffff" }}
        >
          <h4 className="text-center font-semibold text-lg mt-4">
            Please Sign in to continue
          </h4>
          <label htmlFor="email" className="block">
            Email
          </label>
          <input
            style={{ backgroundColor: "#ffffff" }}
            type="email"
            placeholder="Email Address"
            name="email"
            required
            className="w-full p-4 border-2 border-black rounded-lg bg-e9f8f9"
            onChange={handleUserNameChange}
          />
          <br />
          <br />
          <div className="relative">
            <div className="flex justify-between items-center">
              <label htmlFor="Password" className="block">
                Password
              </label>
              <button
                onClick={handleForgotclick}
                className="flex items-center text-sm text-[#3ea0a3] hover:underline"
              >
                <img src={iota} alt="image" className="mr-1" />
                Forgot password?
              </button>
            </div>

            <input
              style={{ backgroundColor: "#ffffff" }}
              type={showPassword ? "text" : "password"}
              placeholder="Enter your Password"
              name="password"
              required
              className="w-full p-4 border-2 border-black rounded-lg bg-e9f8f9"
              onChange={handlePasswordChange}
            />
            <span
              className="mt-2 absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
            </span>
          </div>

          <p className="text-5xl text-red-600 font-bold text-center">
            {message}
          </p>

          <div className="mt-8 btn flex items-center justify-between">
            <button
              className="rounded-full m-2 text-white w-full px-4 py-4 shadow-md hover:bg-white transition duration-200 ease-in"
              style={{
                background:
                  "linear-gradient(to bottom right, #e8cd4d, #eebc3a, #f1b230, #f8a01c)",
              }}
              onClick={handleLoginSubmit}
              disabled={loading}
            >
              {loading ? "Signing In..." : "Sign In"}
            </button>
          </div>
          {/* <h4 className="text-center pt-8 text-9ebbbf">
            Don't have an Account?{" "}
            <span
              className="text-[#2c8686]  font-bold"
              onClick={handleSignupClick}
              style={{ cursor: "pointer" }}
            >
              Signup
            </span>
          </h4> */}
        </div>
      </div>
    );
  } else if (currentPage === "otp") {
    return (
      <div className="otp-verification h-screen w-full flex justify-center items-center">
        <div className="mb-4 p-16 bg-white justify-center space-x-2 rounded-md">
          <div className="text-5xl mb-4 flex justify-center">
            <img className="fas fa-lock" src={lock} alt="Lock Icon" />
          </div>
          <h4 className="text-center font-bold text-5xl mt-4">Verify OTP</h4>
          <p className="text-3xl font-semibold text-gray-600">
            {" "}
            Enter the verification code to login
          </p>
          <br></br>
          <div className="mb-4 flex justify-center space-x-2">
          {otp.map((data, index) => (
   <input
   key={index}
   type="text"
   name="otp"
   maxLength="1"
   value={data}
   onChange={(e) => handleOtpChange(e, index)}
   onKeyDown={(e) => {
     // Handle Backspace: Move focus to previous input if empty
     if (e.key === "Backspace" && index > 0 && !otp[index]) {
       e.target.previousSibling.focus();
     }
     // Handle Enter: Submit OTP or move to next input
     else if (e.key === "Enter") {
       e.preventDefault();
       if (otp.every((digit) => digit !== '')) {
        handleOtpSubmit(); // Submit OTP when all fields are filled
      }
      else {
         e.target.nextSibling?.focus(); // Move focus to next input
       }
     }
   }}
   onFocus={(e) => e.target.select()}
   onPaste={handlePaste}
   className="w-12 h-12 text-center border rounded-md text-lg"
 />
))}
          </div>
          <p className="text-5xl text-red-600 font-bold text-center">
            {message}
          </p>
          
          <div className="mt-8 btn flex items-center justify-between">
            <button
              className="rounded-full m-2 text-white w-full px-4 py-4 shadow-md hover:bg-white transition duration-200 ease-in"
              style={{
                background:
                  "linear-gradient(to bottom right, #e8cd4d, #eebc3a, #f1b230, #f8a01c)",
              }}
              onClick={handleOtpSubmit}
              disabled={loading}
            >
              {loading ? "Verifying OTP..." : "Verify OTP"}
            </button>
          </div>
          {/* <br></br>
          <p className="text-3xl font-semibold text-black">
            
            Did n't receive the OTP <span className="text-gray-600">Resend OTP</span>
          </p> */}
        </div>
      </div>
    );
  }
};

export default Login;
