import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoMdSearch } from "react-icons/io";
import { ImCross } from "react-icons/im";
import { GoDownload } from "react-icons/go";
import Plan from "./Plan"; // Import the Plan component
import Modal from "react-modal"; // Import react-modal for handling modals
import Local from '../assets/images/local.svg';
import GlobalPlan from '../assets/images/Globalplan.svg';
import Regional from '../assets/images/Regional.svg'
const BuyDataRegional = () => {
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [plans, setPlans] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false); // State for modal visibility
  const [selected,setSelected]=useState();

  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const role = localStorage.getItem("role");
    setUserRole(role);
  }, []);

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${apiUrl}ESIM/Get_plancount_regionwise?flag=1`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.data.isSuccess) {
          setRegions(response.data.planlists);
          const europeRegion = response.data.planlists.find(
            (region) => region.regionname === "Europe"
          );
          if (europeRegion) {
            setSelectedRegion(europeRegion);
            fetchPlans(europeRegion.region_id);
            setMenuOpen(true);
          }
        }
      } catch (error) {
        console.error("Error fetching regions:", error);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };

    fetchRegions();
  }, [apiUrl]);

  const fetchPlans = async (regionId) => {
    if (!regionId) return;
    try {
      const token = localStorage.getItem("token");
      const partnerCode = localStorage.getItem("partnerCode");

      const response = await axios.post(
        `${apiUrl}ESIM/Get_plan_byregion`,
        { region_id: regionId, country: "string", partnerCode },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.isSuccess) {
        const sortedPlans = response.data.getofflineplan.sort(
          (a, b) => parseData(a.data) - parseData(b.data)
        );
        setPlans(sortedPlans);
      }
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };

  const parseData = (data) => {
    if (!data) return Infinity;
    const unit = data.slice(-2);
    const value = parseInt(data.slice(0, -2), 10);
    return unit === "GB" ? value * 1000 : value;
  };

  const handleRegionClick = (region) => {
    setSelectedRegion(region);
    fetchPlans(region.region_id);
  };

  const downloadCSV = async () => {
    try {
      const token = localStorage.getItem("token");
      const partnerCode = localStorage.getItem("partnerCode");

      if (!token) {
        setError("No token found. Please log in.");
        return;
      }
      const response = await fetch(`${apiUrl}ESIM/Get_all_plans?flag=1&PartnerCode=${partnerCode}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });
  
      const data = await response.json();
  
      if (data.isSuccess) {
        let { getallplan } = data;
      
        // Sort the plans alphabetically by country
        getallplan = getallplan.sort((a, b) => a.country.localeCompare(b.country));
      
        const header = 'Country,Data,Validity,Website Price,Portal Price,plan_Class\n';
      
        // Retrieve discount from localStorage (default to 20% if not set)
        const discount = parseFloat(localStorage.getItem('Discount')); // Default to 20 if not set
      console.log(discount)
        const rows = getallplan.map(plan => {
          // Calculate the discounted website price based on the discount from localStorage
          const portalPrice = parseFloat(plan.sellingcost);
          const websitePrice = portalPrice * (1 - (discount / 100)); // Apply the discount (100% - discount)
      
          return `${plan.country},${plan.data === "" ? "Unlimited" : plan.data},${plan.validity},$${portalPrice.toFixed(2)},$${websitePrice.toFixed(2)},${plan.plan_Class},`;
        }).join('\n');
        
        // Combine header and rows
      
            
  
        const csvContent = header + rows;
  
        // Create a blob and trigger download
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'plans.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };
  const handleBuyClick = (plan) => {
    localStorage.setItem("planCode", plan.plancode); // Store planid in session storage
    localStorage.setItem("plan_id", plan.planid); // Store planid in session storage

    setPlans(plan);
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handleNavigation = (storeType) => {
    setSelected(storeType);

    if (storeType === "local") {
      navigate("/BuyDataFlag"); // Navigate to Home
    } else if (storeType === "regional") {
      navigate("/BuyDataRegional"); // Navigate to Regional eSIM
    }else{
      navigate("/BuyDataGlobal")
    }
  };
  return (
    <>
    <div className="flex shadow-2xl m-2">
      <button
        onClick={() => handleNavigation("local")}
        className={`self-stretch shadow-[0px_2px_8px_rgba(92,_152,_140,_0.3)] rounded-lg h-10 flex flex-row items-center justify-center p-2.5 box-border m-4 `}
>

        <img src={Regional}
          className={`mr-2 ${
            selected === "local" ? "text-brand-green-selected" : "text-brand-white"
          }`}
        />
        Local eSIM store
      </button>


      <button
        onClick={() => handleNavigation("regional")}
        className={`self-stretch shadow-[0px_2px_8px_rgba(92,_152,_140,_0.3)] rounded-lg h-10 flex flex-row items-center justify-center p-2.5 box-border m-4 ${
          selected === "local"
            ? "bg-brand-white text-brand-green-selected"
            : "bg-brand-green-selected text-brand-white"
        }`}
      >
      
        <img src={Local}
         className="h-6 w-6 filter invert sepia saturate-500 hue-rotate-180 brightness-150 contrast-100"
         alt="Regional Icon"
      
        />
        Regional eSIM store
      </button>
    </div>
      {/* Search Bar */}
      <div className="bg-[#117575] p-3 mb-2 rounded-xl">
        <div className="relative">
          <input
            type="text"
            placeholder="Search Regions"
            className="w-full self-stretch rounded-2xl bg-brand-gray h-10 flex flex-row items-center justify-start box-border gap-[12px] mr-2 pl-10"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-3">
            <IoMdSearch size={20} className="text-gray-500" />
          </div>
        </div>
      </div>

      {/* Regions Section */}
      <div className="flex flex-col gap-1 justify-between bg-gray-100 p-3">
  {selectedRegion && (
    <div className="flex">
          <span className="bg-[#c5e1e2] text-black font-semibold  shadow-md p-2 flex items-center rounded-2xl">
          <ImCross size={12} className="cursor-pointer mr-2" />
        {selectedRegion.regionname}
      </span>
    </div>
  )}

  {/* Display "Top Regions" once before the filtered list */}
  <h1 className="text-xl font-bold">Top Regions</h1>

  {/* Skeleton Loader */}
  {loading ? (
    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-6">
      {Array(5)
        .fill(0)
        .map((_, index) => (
          <div key={index} className="bg-gray-300 h-10 w-24 rounded-xl animate-pulse"></div>
        ))}
    </div>
  ) : (
    <div className="flex flex-wrap gap-4">
    {regions
      .filter(
        (region) =>
          region.regionname.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .map((region) => (
        <div
            key={region.region_id}
            onClick={() => handleRegionClick(region)}
            className={`w-24 h-30 flex flex-col items-center justify-center border border-gray-300 rounded-lg p-2 cursor-pointer transition-transform transform hover:scale-105 hover:shadow-lg ${
              selectedRegion?.region_id === region.region_id
                ? "bg-[#DAEFEF  ] text-white border-2 border-[#117575] shadow-md scale-105"
                : "bg-white text-black"
            }`}
          >
            <div className="flex flex-col items-center justify-center">
              <img
                src={`https://www.airhubapp.com/assets/flags/${region.regionname}.svg`}
                className="w-12 h-12 shadow-2xl border-2 rounded-3xl"
                alt={`${region.regionname} Flag`}
              />
              <h1
                className={`text-sm font-medium mt-2 ${
                  selectedRegion?.region_id === region.region_id
                  ? "text-black"
                  : "text-gray-700"
                }`}
              >
                {region.regionname}
              </h1>
            </div>
          </div>

      ))}
  </div>
  
  )}
</div>

      {/* Plans Table */}
      {selectedRegion && plans.length > 0 && (
        <div className="mt-4 p-4 border rounded-md bg-gray-100">
       <div className="flex items-center justify-between mb-4">
       
       <div className="flex items-center">
       <img
              src={`https://www.airhubapp.com/assets/flags/Middle-east.svg`}
              className="w-12 h-12 "
              alt="Flag"
            />
            <span className="ml-2 text-5xl text-black font-bold">{selectedRegion.regionname} Packages</span>
        </div>
          <button
            onClick={downloadCSV}
            className="px-4 py-2 text-[#105255] rounded-md font-bold flex items-center space-x-2"
          >
            <GoDownload size={28} />
            <span>Export</span>
          </button> 
          </div>
          <table className="w-full">
            <thead>
              <tr className="border-b">
                <th className="text-center p-3 px-5">Country/Region</th>
                <th className="text-center p-3 px-5">Data</th>
                <th className="text-center p-3 px-5">Validity (Days)</th>
                <th className="text-center p-3 px-5">Plan Code</th>
                <th className="text-center p-3 px-5">Price</th>
                <th className="text-center p-3 px-5">Operator</th>
                {/* <th className="text-center p-2 px-2">Buy</th> */}
              </tr>
            </thead>
            <tbody>
              {plans.map((plan) => (
                <tr key={plan.planid} className="border-b hover:bg-gray-200">
                  <td className="text-center p-3">{plan.geography}</td>
                  <td className="text-center p-3">{plan.data || "Unlimited"}</td>
                  <td className="text-center p-3">{plan.vaildity}</td>
                  <td className="text-center p-3">{plan.plancode}</td>
                  <td className="text-center p-3 px-5">${plan.sellingcost}</td>
                  <td className="text-center p-3 px-5">{plan.operatorname}</td>
                  <td className="text-center ">
                     <button className="self-stretch shadow-[0px_2px_8px_rgba(92,_152,_140,_0.3)]  rounded-xl bg-brand-green-selected  border-blue-500 h-10 flex flex-row items-center justify-center p-4 box-border text-brand-white m-4"
                       onClick={() => handleBuyClick(plan)}>Buy</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
         <Modal
       isOpen={modalIsOpen}
       onRequestClose={closeModal}
       style={{
         overlay: {
           backgroundColor: "rgba(0, 0, 0, 0.5)",
         },
         content: {
           width: "80%",
           margin: "auto",
           padding: "0px",
           borderRadius: "8px",
           height: "fit-content",
         },
       }}
     >
       <div className="absolute top-4 right-5 flex justify-center items-center">
         <button onClick={closeModal} className="text-black">
           <ImCross size={20} />
         </button>
       </div>
       {setPlans && <Plan plan={setPlans} closeModal={closeModal} />}
     </Modal>
    </>
  );
};

export default BuyDataRegional;
