import React, { useState, useEffect, useRef } from 'react';
import logoimage from "../assets/images/10337609.png";
import { CiBellOn } from "react-icons/ci";
import { MdOutlineQuestionMark } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { RiArrowDropDownLine } from "react-icons/ri";
import Modal from 'react-modal';
import Step1 from "../pages/Step1";

const Navbar = () => {
  const navigate = useNavigate();

  const [modalIsOpen, setModalIsOpen] = useState(false); // State to manage modal visibility
   
  const handleNavigate = () => {
    navigate('/HelpSupport');
  };

  const handleLogout = () => {
    localStorage.clear(); // Clear all session data
    navigate('/'); // Navigate to home page after logout
  };
  const handleProfile  = () => {
    navigate('/UserProfile'); // Navigate to home page after logout
  };

  const [isMessagesOpen, setIsMessagesOpen] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const dropdownRef = useRef(null);

  useEffect(() => {
    const storedName = localStorage.getItem('Name');
    const storedUsername = localStorage.getItem('username');
    
    if (storedName) {
      setName(storedName);
    }
    if (storedUsername) {
      setUsername(storedUsername);
      console.log(username)
    }

    // Event listener to close dropdowns on click outside
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowLogout(false);
        setIsMessagesOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);
  const [userRole, setUserRole] = useState(null); // State for user role

  useEffect(() => {
    // Fetch user role from localStorage
    const role = localStorage.getItem("role");
    setUserRole(role);
  }, []);

  return (
    <nav  className="mx-2 rounded">
      <div className="flex justify-between items-center mx-auto ">
        <div className="flex justify-between items-center p-2 w-full">
          <div className="main-nav-left font-bold text-lg"></div>
          <div className="main-nav-right flex items-center">
            <div className="flex items-center relative">
              {userRole === "Partner" &&(
              <div className="main-nav-right-icons mr-4 bg-white rounded-full p-2"onClick={handleNavigate} >
                <MdOutlineQuestionMark className="text-lg" />
              </div>
              )}
              <div className="main-nav-right-icons user flex items-center relative" ref={dropdownRef}>
                <div className="w-auto h-8 rounded-full mr-2 bg-gradient-to-br from-teal-400 to-teal-600 flex items-center justify-center p-6">
                  <img
                    src={logoimage}
                    className="w-6 h-6 rounded-full"
                    alt="User Avatar"
                  />
                  <div className="text-white font-bold ml-2 flex items-center">
                    {/* <button onClick={handleNavigateToDetails}>{name}</button> */}
                    <button>{name}</button>
                    <RiArrowDropDownLine size={40} onClick={() => setShowLogout(!showLogout)} />
                  </div>
                </div>
                {showLogout && (
                <div className="absolute top-12 right-0 bg-white border border-gray-300 rounded-md p-2 shadow-lg z-50">
                  <button
                    className="block w-full text-left py-2 px-4 text-gray-800 hover:bg-gray-200"
                    onClick={handleProfile}
                  >
                    Profile
                  </button>
                  <hr />
                  <button
                    className="block w-full text-left py-2 px-4 text-gray-800 hover:bg-gray-200"
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </div>
              )}
              </div>
            </div>
          </div>
        </div>

      </div>

 
    </nav>
    
  );
};

export default Navbar;
